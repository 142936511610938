import * as React from "react"
import { Layout } from "../components/Layout";
import { Description } from "../components/Description";
import { PageTitle } from "../components/PageTitle";
import { StaticImage } from "gatsby-plugin-image";

export const Head = () => 
  <>
    <title>Lighthouses, Lightships and Daymarks from around the world | Worldwide Lighthouses</title>
    <meta name="description" content="Lighthouses, Lightships and Daymarks from around the world" />
  </>;

const IndexPage = () => {
  return (
    <Layout>
      <PageTitle title="Welcome to Worldwide Lighthouses" />
      <StaticImage 
        imgStyle={{ "borderRadius": "5px"}} 
        style={{ "borderRadius": "8px", width: "100%", margin: "auto", marginTop: "10px", border: "3px solid #1E1E1E" }} 
        src="../images/IMG_7303.jpeg"
        alt="The famous beachy head lighthouse"
      />
      <Description>
        <p>Worldwide Lighthouses is an ongoing project by the author to photograph as many significant maritime navigation markers around the world as possible, to build up a record of their history, evolving design, advances in technology and in some cases the replacement or destruction.</p>
        <p>At present the website mostly features Lighthouses, Lightships and Daymarks in the United Kingdom and The Republic of Ireland, as well as a handful from Europe and the East Coast of the USA.</p>
      </Description>
    </Layout>
  )
}

export default IndexPage;
